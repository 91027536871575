import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function CookiesBandeau() {
  const [showBandeau, setShowBandeau] = useState(false);

  useEffect(() => {
    // Vérifie si le consentement a déjà été donné
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBandeau(true); // Affiche le bandeau si pas de consentement
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBandeau(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowBandeau(false);
  };

  if (!showBandeau) {
    return null; // Ne montre pas le bandeau si le consentement est donné
  }

  return (
    <div className="cookies-bandeau">
      <p className="cookies-bandeau__text">
        Nous utilisons des cookies sur notre site Web pour vous offrir l'expérience la plus pertinente et à des fins d'analyse.
        Ce site utilise des cookies afin d'analyser le trafic et de mesurer les performances des annonces.
        <br />En cliquant sur "Accepter", vous consentez à l'utilisation de TOUS les cookies.
        Consultez notre <Link to="/politique-de-confidentialite">politique de confidentialité</Link> pour plus d’informations.
        {' '}
      </p>
      <div className="cookies-bandeau__buttons">
        <button className="cookies-bandeau__button" onClick={handleAccept}>
          ACCEPTER
        </button>
        <button className="cookies-bandeau__button" onClick={handleReject}>
          REFUSER
        </button>
      </div>
    </div>
  );
}
